
#sidebar {
    font-family: 'nunito';
    position: fixed;
    top: -180px;
    width: 100px;
    z-index: 1;
}
.closed-mb-tab {
    background-color: white;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    height: 56px;
    left: 0;
    position: fixed;
    top: 89px;
    width: 56px;
}
.closed-mb-tab i {
    color: #223336;
    font-size: 1.6em;
    left: 14px;
    position: relative;
    top: 18px;
}
#SidebarPane {
    font-family: 'nunito';
}	
.SidebarPane {
    padding-top: 50px;
    position: relative;
    transition: 0.5s ease 0s;
}
.li-pages {
    left: 0;
    width: auto;
}
.pages-icon i {
    color: #223336;
    font-size: 1.5em;
    padding-left: 16px;
    padding-top: 26px;
}
#page-bg {
    height: 58px;
}
.pages-icon {
    display: inline-block;
}
#ipad-hidden-icon {
    display: none;
}
#appearance-icon {
    display: none;
    padding-left: 3px;
}
.mobile-menu_bg {
    background-color: white;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    height: 204px;
    left: -85px;
    position: fixed;
    top: 94px;
    transition: 0.5s ease 0s;
    width: 56px;
}
.pages-icon i {
    color: #223336;
    font-size: 1.5em;
    padding-left: 16px;
    padding-top: 26px;
    font-weight: 300;
	font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -.0667em;
}
#reports-icon {
    display: none;
}
#reports-icon i {
    padding-left: 11px;
}
#help-icon {
    margin-bottom: 22px;
}
.pin-btn {
    background-color: #F3F3F3;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    height: 52px;
    width: 56px;
}
#pin-icon {
    padding-left: 6px;
}
#pin {
    font-size: 1.3em;
    margin-top: -2px;
    padding-top: 21px;
}


.edit-menu {
	height: 59px;
	width: 300px;
}

.edit-menu .page-icon {
	padding-top: 13px;
}

.edit-menu .page-text {
	width: auto;
}
#edit-container {
	top: 50px;
}
.menu_container {
    z-index: 200;
    background-color: #E7EEF0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: 55px;
    padding-left: 1px;
    position: absolute;
    top: 120px;
    transition: 1s 0s;
    width: 320px;
    z-index: -1;
}
.arrow {
    float: right;
}
.menu_container .arrow::after {
    border-bottom: 61px solid transparent;
    border-left: 45px solid #ffffff;
    border-right: 0 solid transparent;
    border-top: 58px solid transparent;
    content: "";
    height: 0;
    left: 0;
    margin-left: -1px;
    margin-top: -17px;
    position: absolute;
    top: 16px;
}
.secondary-arrow::after {
    border-bottom: 20px solid transparent;
    border-left: 16px solid #E7EEF0;
    border-right: 8px solid transparent;
    border-top: 17px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 91%;
    top: 0;
}
.menu_header {
    padding: 16px 16px 10px 0;
    text-align: center;
    background-color: #E7EEF0;
    border-top-right-radius: 4px;
    height: 48px;
}

.menu_bg {
    float: left;
    width: auto;
    border-bottom-right-radius: 4px;
}
.rect-menu {
    border-bottom: solid 1px #E7EEF0;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: solid 1px #E7EEF0;
    box-sizing: content-box;
    cursor: pointer;
    margin: 0 !important;
    padding: 7px 0;
    width: 256px;
}
.side-menu-page {
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    display: table;
    height: 49px;
    margin-left: 26px;
    margin-top: 8px;
}
.page-icon {
    color: #d8d8d8;
    display: flex;
    font-size: 1.2em;
    justify-content: flex-start;
    padding-left: 15px;
    padding-top: 18px;
}
.edit-menu .page-text {
    width: auto;
}
.side-menu-page p {
    align-items: center;
    display: block;
    height: 18px;
    line-height: 1.5;
    margin-top: 8px;
    overflow: hidden;
    padding-bottom: 4px;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 248px;
}
.page-text {
    display: inline-block;
    margin-bottom: 4.5px;
    margin-top: -7px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 137px;
}
#SidebarPane h2 {
    font-size: 14px;
    font-weight: 400;
}
.menu_header h2 {
    margin-bottom: 0;
}
.side-menu-page {
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    display: table;
    height: 49px;
    margin-left: 26px;
    margin-top: 8px;
}
.pages-icon .narrow-icon {
    padding-left: 10px;
}
.close-side-menu {
    float: right;
    margin: 0;
}
.down-arrow::after {
    border-left: 21px solid transparent;
    border-right: 23px solid transparent;
    border-top: 18px solid #E7EEF0;
    content: "";
    height: 0;
    position: absolute;
    right: 304px;
    margin-top: 9px;
    z-index: 1;
}
.pages-helper-text {
    background-color: #ffffff;
    float: left;
    height: 479px;
    transition: 1s ease 0s !important;
    width: 110px;
    overflow: hidden;
}
#SidebarPane h2 {
    font-size: 14px;
    font-weight: 400;
}
.pages-helper-text h2 {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    margin-top: 24px;
    padding-left: 11px;
    padding-right: 5px;
}
.pages-helper-text p {
    margin-bottom: 2px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
}
#pages-help {
    bottom: 58px;
    height: 63px;
    transition: 1s 0ms;
}
.more-help {
    bottom: 0;
    cursor: pointer;
    height: 55px;
    left: 0;
    position: absolute;
    width: 110px;
}
#pages-help #video-help-icon {
    padding-top: 83px;
}
#video-help-icon {
    cursor: pointer;
    font-size: 1.3em;
    margin-left: 3px;
    margin-top: 0;
    padding-left: 6px;
}
.btn-icon i {
    color: #223336;
    display: table-cell;
    padding-left: 10px;
    padding-right: 8px;
    padding-top: 9px;
}
.more-help i {
    color: #223336;
    float: left;
    font-size: 1.3em;
    margin-left: 10px;
    margin-top: 10px;
    padding-right: 5px;
}
#page_menu_bg {
    float: left;
    width: 675px;
    border-bottom-right-radius: 11px;
}
.menu_bg {
	width: 100%;
    float: left;
    width: auto;
    border-bottom-right-radius: 4px;
}
.add-page {
    background-color: #084056;
    cursor: pointer;
    display: inline-block;
    height: 47px;
    margin-top: 0;
    top: 0;
    width: 362px;
}	
.add-page i {
    color: #223336;
    float: left;
    margin-left: 74px;
    margin-right: 10px;
    margin-top: 17px;
    width: 24px;
}
.add-page h2 {
    color: #223336;
    font-weight: 500;
    margin-top: 0;
    padding-left: 67px;
    padding-top: 16px;
}
.site-map-btn {
    background-color: #abcdcc;
    cursor: pointer;
    display: inline-block;
    height: 47px !important;
    margin-top: 0;
    top: 36px !important;
    width: 327px !important;
    right: 0 !important;
}
.site-map-btn {
    border-radius: 2px;
    height: 32px;
    position: absolute;
    background-color: #199AB7;
    width: 134px;
    top: 100px;
    right: 18px;
}
.site-map-btn i {
    color: #223336;
    float: left;
    margin-left: 81px;
    margin-right: 35px;
    margin-top: 9px;
    width: 24px;
}
.site-map-btn i {
    display: inline-block;
    padding-left: 10px;
    padding-top: 7px;
}
.pages-container-pages {
    height: 374px;
    padding-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 1px;
    position: relative;
    transition: 1s 0s;
    background-color: #EFF3F3;
}
.lds-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -17px;
    margin-left: -17px;
}
.scroll-content {
    box-sizing: border-box;
    right: 0;
}
.side-menu-page {
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    display: table;
    height: 49px;
    margin-left: 26px;
    margin-top: 8px;
}
.menu-pages {
    background-image: none !important;
    margin-left: 22px;
    width: 216px;
    height: 35px;
}
.tools-icons {
    float: right;
    height: 35px;
    position: relative;
    width: 61px;
}
.more-icon {
    color: #D4E1E5;
    float: right;
    margin-right: 10px;
    margin-top: 4px;
    position: relative;
    vertical-align: top;
}
.home-page-icon i {
    color: #d8d8d8;
    margin-top: 9px;
    margin-left: 325px;
    position: absolute;
}

.home-active {
    color: #576E7F !important;
}
.drag-page-icon {
    color: #223336;
    float: left;
    font-size: 15px;
    padding-left: 12px;
    margin-top: 6px;
}
.side-menu-page p {
    align-items: center;
    display: block;
    height: 18px;
    line-height: 1.5;
    margin-top: 8px;
    overflow: hidden;
    padding-bottom: 4px;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 248px;
}
.menu-pages p {
    width: 137px;
    margin-left: 36px;
}
.page-text {
    display: inline-block;
    margin-bottom: 4.5px;
    margin-top: -7px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 137px;
}
.site-map-btn h2 {
    color: #223336;
    font-weight: 500;
    margin-top: 0;
    padding-left: 67px;
    padding-top: 16px;
}
@media only screen and (min-width:1600px) {
	.edit-menu .side-menu-page p {
		margin-top: -17px;
	}
	.edit-menu .page-icon {
		margin-top: 17px;
	}
}

@media only screen and (min-width: 767px){
.closed-mb-tab {
    top: 89px;
}
.pages-icon {
    margin-bottom: 29px;
    margin-left: 18px;
    margin-top: 0;
}
.pages-icon i {
    padding-left: 0;
    padding-top: 0;
}
.pages-icon i {
    color: #223336;
    font-size: 1.5em;
    padding-left: 16px;
    padding-top: 26px;
}
}
@media only screen and (min-width: 1200px) and (min-height: 612px) and (max-height: 730px){
.closed-mb-tab {
    top: 18.4vh;
}
.SidebarPane {
    top: 239px;
}
.menu_icon {
    display: flex;
    flex-direction: column;
    height: 43.5vh;
    justify-content: space-around;
}
#page-edit {
    margin-bottom: 2vh;
    margin-top: 0;
}
.top-icons {
    height: 34vh;
    margin-top: -3vh;
}
#page-bg {
    height: auto;
}
.pages-icon {
    margin-bottom: 4vh;
    margin-top: 3vh;
}
#modules-icon {
    margin-top: 1vh;
}
.pages-icon {
    margin-bottom: 4vh;
    margin-top: 3vh;
}
#appearance-icon {
    display: flex;
    margin-top: 2px;
}
.mobile-menu_bg {
    display: flex;
    flex-direction: column;
    height: 41.5vh;
    justify-content: space-around;
    top: 52vh;
}
#users-icon {
    margin-top: 0;
    padding-top: 0;
}
.mobile-menu_bg .pages-icon {
    margin-bottom: 3.9vh;
    margin-top: .09vh;
}
.pages-icon {
    margin-bottom: 4vh;
    margin-top: 3vh;
}
.mobile-menu_bg .pages-icon {
    margin-bottom: 3.9vh;
    margin-top: .09vh;
}
.mobile-menu_bg .pages-icon {
    margin-bottom: 3.9vh;
    margin-top: .09vh;
}
.pin-btn {
    bottom: 0;
    height: 58px;
    margin-top: -26px;
    padding-top: 0;
    position: fixed;
}
.mobile-menu_bg .pages-icon {
    margin-bottom: 3.9vh;
    margin-top: .09vh;
}
#edit-container {
	height: 96px;
}
#edit-container .rect-menu {
	border: 0;
	border-bottom: solid 1px #E7EEF0;
	border-right: solid 1px #E7EEF0;
}
}
@media only screen and (min-width: 1025px) and (min-height: 612px) and (max-height: 640px){
.menu_icon {
    display: flex;
    flex-direction: column;
    height: 44.7vh;
    justify-content: space-around;
}
#page-edit {
    margin-bottom: 7vh;
    margin-top: 3vh;
}
#page-bg {
    height: auto;
}
.pages-icon {
    margin-bottom: 4vh;
    margin-top: 2vh;
}
.pages-icon .narrow-icon {
    padding-left: 10px;
}
.pages-icon i {
    font-size: 1.6em;
    padding-left: 7px;
    padding-top: 7px;
}
.pages-icon {
    margin-bottom: 4vh;
    margin-top: 2vh;
}
.mobile-menu_bg {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 45vh;
    justify-content: space-around;
    top: 47vh;
}
#users-icon {
    margin-top: -1vh;
}
.mobile-menu_bg .pages-icon {
    margin-bottom: 3vh;
}
.pin-btn {
    bottom: 0;
    height: 58px;
    margin-top: 5px;
    position: fixed;
}

#edit-container {
	height: 96px;
	width: 221px;
}
#edit-container .rect-menu {
	border-bottom: 0;
	border-left: 0;
	width: 218px;
}
}
@media only screen and (min-width: 1025px) and (min-height: 612px) and (max-height: 640px){
.SidebarPane {
    top: 172px;
}
.pages-icon i {
    font-size: 1.6em;
    padding-left: 7px;
    padding-top: 7px;
}
}

@media only screen and (min-width: 1025px){
#sidebar {
    z-index: 40;
}
.menu_icon {
    background-color: white;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    display: inline-block;
    height: 476px;
    width: 60px;
}
.pages-icon {
    margin-bottom: 12px;
    margin-left: 11px;
    margin-top: 10px;
}
#ipad-hidden-icon {
    display: block;
}
#modules-icon {
    margin-top: 2px;
}
#modules-icon .fa-plug {
    display: block;
}
.pages-icon .narrow-icon {
    padding-left: 10px;
}
#appearance-icon {
    display: block;
    padding-top: 2px;
}
.pages-icon i {
    font-size: 1.6em;
    padding-left: 7px;
    padding-top: 7px;
}
.mobile-menu_bg {
    height: 231px;
    left: -60px;
    top: 287px;
    width: 60px;
}
#users-icon {
    margin-top: 0;
}
#users-icon i {
    padding-top: 20px;
    padding-left: 9px;
}
.pages-icon i {
    font-size: 1.6em;
    padding-left: 7px;
    padding-top: 7px;
}
#reports-icon {
    display: block;
}
.pin-btn {
    height: 60px;
    width: 60px;
}
#pin-icon {
    margin-left: 13px;
    padding-top: 4px;
}
#pin {
    font-size: 1.4em;
    margin-top: 9px;
}
.edit-menu .side-menu-page p {
	margin-top: -17px;
	width: 230px;
	padding-left: 61px;
}
.edit-menu .page-icon {
	margin-top: 17px;
	padding-top: 0;
}
.edit-menu .rect-menu {
	width: 257px;
}
#edit-container {
	width: 259px;
}
#edit-container .arrow::after {
	border-bottom: 19px solid transparent;
	border-left: 19px solid #ffffff;
	border-right: 14px solid transparent;
	border-top: 18px solid transparent;
	content: "";
	height: 0;
	margin-top: -16px;
	position: absolute;
	left: 3px;
	top: 16px;
}
#edit-container {
	height: 96px;
	width: 221px;
}
#edit-container .rect-menu {
	border-bottom: 0;
	border-left: 0;
	width: 218px;
}
.menu_container {
    margin-left: 58px;
}
#edit-container .arrow::after {
    border-bottom: 19px solid transparent;
    border-left: 19px solid #ffffff;
    border-right: 14px solid transparent;
    border-top: 18px solid transparent;
    content: "";
    height: 0;
    margin-top: -16px;
    position: absolute;
    left: 3px;
    top: 16px;
}
.menu_header {
    padding: 10px 16px 10px 0;
    text-align: center;
    height: 36px;
}
.menu_bg {
    width: 100%;
}
.menu_bg {
    float: left;
    width: auto;
    border-bottom-right-radius: 4px;
}
.edit-menu .rect-menu {
    width: 257px;
}
.rect-menu {
    height: 58px;
    padding: 0;
}
.page-icon {
    padding-left: 15px;
    padding-top: 13px;
}
.edit-menu .side-menu-page p {
    margin-top: -17px;
    width: 230px;
    padding-left: 61px;
}

.pages-menu-container {
    background-color: white;
    border-bottom: solid 1px #E7EEF0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: solid 1px #E7EEF0;
    border-top-right-radius: 4px;
    display: block;
    margin-left: 58px;
    padding-left: 1px;
    position: absolute;
    top: 109px;
    width: 787px;
    z-index: -1;
}
.pages-menu-container .menu_header {
    text-align: left;
    padding-left: 56%;
}
.menu_header {
    padding: 10px 16px 10px 0;
    text-align: center;
    height: 36px;
}
.pages-menu-container .menu_header {
    text-align: left;
    padding-left: 56%;
}
#page_menu_bg {
    transition: 1s 0s;
}
.page-wrapper {
    margin-top: -5px;
    position: relative;
}
.pages-menu-container .page-item {
    position: relative;
}
.pages-menu-container .side-menu-page:hover, .pages-menu-container .side-menu-page:active, .pages-menu-container .side-menu-page.activePage, .pages-menu-container .side-menu-page.currentTab {
    background-color: #d8e5ec;
    cursor: url(/Portals/_default/skins/myhub2/assets/img/openhand.cur),move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.pages-menu-container .page-menu {
    table-layout: fixed;
    width: 372px;
    height: 35px;
}
.pages-menu-container .side-menu-page:hover .tools-icons, .pages-menu-container .side-menu-page:active .tools-icons, .pages-menu-container .side-menu-page.activePage .tools-icons, .pages-menu-container .side-menu-page.currentTab .tools-icons {
    cursor: pointer;
}
.pages-menu-container .side-menu-page:hover .more-icon, .pages-menu-container .side-menu-page:active .more-icon, .pages-menu-container .side-menu-page.activePage .more-icon, .pages-menu-container .side-menu-page.currentTab .more-icon {
    color: #576E7F;
    cursor: pointer;
}
.pages-menu-container .sub-pages.empty-sub-pages {
    display: block;
    height: auto;
    min-height: 10px;
}
.pages-menu-container .sub-pages {
    height: 10px;
    margin: -5px auto;
    overflow: hidden;
    padding-bottom: 5px;
    padding-left: 35px;
    padding-top: 5px;
}

}
@media only screen and (min-width: 1200px){
.closed-mb-tab {
    height: 58px;
    top: 140px;
    width: 60px;
}
.SidebarPane {
    left: -60px;
    top: 270px;
    width: 0;
}
.closed-mb-tab i {
    left: 17px;
    top: 18px;
}
#appearance-icon {
    margin-top: 13px;
}
.mobile-menu_bg {
    height: 231px;
    left: -60px;
    top: 338px;
    width: 60px;
}
.edit-menu .side-menu-page p {
	margin-top: -16px;
}
.edit-menu .page-icon {
	margin-top: 17px;
}
.edit-menu .page-icon {
    padding-top: 0;
}
.edit-menu .side-menu-page p {
    margin-top: -16px;
}


}