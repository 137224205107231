
body {
	font-family: 'nunito' ;
    background-color: red;
}
.ml-auto, .mx-auto {
    margin-left: auto!important;
}
.section-block {
    padding: 90px 0px 90px 0px;
    background-color: #fff;
}
.section-block-grey {
    padding: 90px 0px 10px 0px;
    background-color: #f9f9f9;
}
.section-heading {
    margin-bottom: 10px;
}
.section-heading span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #ED7D31;
    line-height: 200%;
    margin-bottom: 10px;
}
.section-heading h3 {
    position: relative;
    font-size: 35px;
    font-weight: 600;
    color: #111;
    line-height: 130%;
    margin-bottom: 10px;
}
.section-heading-line {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: #ED7D31;
    border-radius: 25%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 5px;
}
.section-heading p {
    font-size: 15px;
    font-weight: 400;
    color: #aaa;
    line-height: 160%;
    padding-top: 15px;
    margin-bottom: 0px;
}
.section-clients-grey {
    background: #f9f9f9;
    padding: 30px 0px;
}

.section-heading-line-left {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: #ED7D31;
    border-radius: 25%;
    margin-top: 15px;
    margin-bottom: 5px;
}
.text-content-big p {
    font-size: 15px;
    font-weight: 400;
    color: #999;
    line-height: 200%;
    margin-bottom: 15px;
}
.section-heading h4 {
    position: relative;
    font-size: 30px;
    font-weight: 600;
    color: #111;
    line-height: 160%;
    margin-bottom: 10px;
}


.button-sm {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    padding: 12px 40px;
}

.primary-button {
    background: #084056;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50px;
    padding: 12px 40px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
}
.primary-button:hover {
    background: #222;
    color: #fff;
}
.section-heading h5 {
    position: relative;
    font-size: 25px;
    font-weight: 600;
    color: #111;
    line-height: 160%;
    margin-bottom: 10px;
}

// card sections
.card {
    margin-bottom: 30px;
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgb(8 21 66 / 5%);
    box-shadow: 0 0 20px rgb(8 21 66 / 5%);
}
.card .card-header {
    background-color: #fff;
    padding: 15px 40px;
    border-bottom: 1px solid #ecf3fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
}
.card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
    margin-bottom: 0;
    text-transform: capitalize;
}
.card .card-header h5 {
    font-size: 22px;
}
.card .card-body {
    padding: 10px 25px;
    background-color: rgba(0,0,0,0);
}


.page-title-section {
    padding: 110px 0px 110px 0px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
}
.page-title-section ul li {
    display: inline-block;
}
.page-title-section ul li a, span{
    // color: #fff;
    font-size: 14px;
    font-weight: 500;
}
.page-title-section ul li a:after {
    content: '\f105';
    color: #fff;
    font-family: 'FontAwesome';
    padding: 0px 10px;
}
.tarawera{
    background-color: #ED7D31;
}
.table-bordered td, .table-bordered th{
    border-color: #084056 !important;
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    vertical-align: middle;
    background-color: #fff;
    background-image: url(../images/down-arrow.png);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #6c757d;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="search"] {
    border: 1px solid #6c757d;
    padding: 0 10px;
    margin-left: 10px;
    height: 37px;
    border-radius: 0;
}
input {
    display: inline-block !important;
    // width: 100%;
    margin-bottom: 0;
    margin-left: 0.5em;
}   

// autth common css
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
body{
  font-family: 'nunito'  !important;
}
.btn {
  font-size: 14px;
}
.btn-light {
  background-color: rgba(115,102,255,0.06) !important;
  border-color: rgba(115,102,255,0.06) !important;
}
.card {
    margin-bottom: 30px;
    border: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 10px !important;
    -webkit-box-shadow: 0 0 20px rgb(8 21 66 / 5%);
    box-shadow: 0 0 20px rgb(8 21 66 / 5%);
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    // border-radius: .25rem;
}


.card:hover {
    -webkit-box-shadow: 0 0 40px rgb(8 21 66 / 5%);
    box-shadow: 0 0 40px rgb(8 21 66 / 5%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.card .card-header {
    background-color: #fff;border-radius:15px !important;
    padding: 30px 0px 20px 40px;
    border-bottom: 1px solid #ecf3fa;
    // border-top-left-radius: 15px;
    // border-top-right-radius: 15px;
    position: relative;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
    margin-bottom: 0;
    text-transform: capitalize;
}
.card .card-header h5 {
    font-size: 18px;
    font-family: 'nunito';
}
.card .card-header>span {
    font-size: 12px;
    color: rgba(43,43,43,0.7);
    margin-top: 5px;
    display: block;
    letter-spacing: 1px;
}
.card .card-body {
    // padding: 40px;
    background-color: rgba(0,0,0,0);
}
.card .card-footer {
  background-color: #fff;
  border-top: 1px solid #ecf3fa;
  padding: 40px;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: .7px;
}
.font-weight-500{font-weight:500}

// forms
.theme-form input[type=text], .theme-form input[type=email], .theme-form input[type=search], .theme-form input[type=password], .theme-form input[type=number], .theme-form input[type=tel], .theme-form input[type=date], .theme-form input[type=datetime-local], .theme-form input[type=time], .theme-form input[type=datetime-local], .theme-form input[type=month], .theme-form input[type=week], .theme-form input[type=url], .theme-form input[type=file], .theme-form textarea, .theme-form select {
  border-color: #efefef;
  background-color: #fff;
  font-size: 14px;
  color: #898989;
  padding:5px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.form-control {
  // display: block;
  width: 100% !important;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f3f3ff;
  background-clip: padding-box;
  border: 1px solid #b9c1cc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}
.btn-outline-primary{
  // background-color: #06124e;
  border-color: #06124e;
  color:#06124e;
}
label:not(.form-check-label, .custom-file-label) {
  font-weight: 500;
}
.btn-outline-primary:hover{
  // background-color: #06124e;
  background-color: #06124e;
  color:#fff !important;
}
.bg-primary {
  background-color: #084056 !important;
  color: #fff;
}
.btn-primary{
  background-color: #084056;
}
.m-r-15 {
  margin-right: 15px;
}
.o-hidden {
  overflow: hidden;
}
.b-r-4 {
  border-radius: 4px !important;
}
.card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
  margin-bottom: 0;
  text-transform: capitalize;
}
.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 35px;
  top: 33px;
  display: inline-block;
  float: right;
  padding: 8px 0;
  position: absolute;
  background-color: #fff;
  z-index: 1;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
.f-w-600{font-weight:600}
@media (min-width: 1200px){
h4, .h4 {
    font-size: 1.5rem;
}
}
.card .card-header .card-header-right .card-option {
  text-align: right;
  width: 35px;
  height: 20px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.card .card-header .card-header-right .card-option li {
  display: inline-block;
}
.card .card-header .card-header-right .card-option li:first-child i {
  -webkit-transition: 1s;
  transition: 1s;
  font-size: 16px;
  color: var(--theme-deafult);
}
.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2c323f;
  line-height: 20px;
}

@media screen and (max-width: 1660px) and (min-width: 1200px){
.xl-50 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
}
.xl-100 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
}
}
@media only screen and (max-width: 991.98px){
h4 {
    font-size: 22px;
}
.card .card-header, .card .card-body, .card .card-footer {
  padding: 20px !important;
}
.card .card-header .card-header-right {
  top: 11px;
  right: 15px;
}
}
@media only screen and (max-width: 480px){
h6 {
    font-size: 14px;
}
}
@media only screen and (max-width: 575.98px){
.btn {
    padding: 6px 20px !important;
}
}
.logo img{
  width:80%;
}
.main-header{
  position: fixed !important;width: 100%;
}
:not(.layout-fixed) .main-sidebar{
  position: fixed;
}

.noti{
  position:fixed;
  right: 0;
}

.bg-east-blue{
  background-color: #199AB7;
}
// auth common css endtransform

@media (min-width: 576px) {
  .modal-dialog.user-detail-modal {
      max-width: 80vw;
      margin: 1.75rem auto;
  }
}
.theme-green .bs-datepicker-head{
background-color: #cb7732 !important;
}
.theme-green .bs-datepicker-body table td.week span{
  color: #c66f27 !important;
}

// .bs-datepicker{
//   left: 120px;
// }

.btn-outline-primary {
  border-color: #d3d5d7 !important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{
  background-color:  #c66f27 !important;
}
.btn-outline-primary:hover{
  background-color:  #c66f27 !important;
}
.btn[_ngcontent-vjx-c144] {
  background-color: #c66f27 !important;
  font-size: 12px;
}
.table-responsive {
  // display: block;
  width: 100%;
  // overflow-x: hidden;
  // -webkit-overflow-scrolling: touch;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #c66f27 !important;
}

